<template>
  <t-split-screen centered>
    <div class="content--centered">
      <div v-if="status === 'loading'" style="display: flex">
        <span class="loading mt-1" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>
            {{ $t('pages.applicant_looking_renewal.loading.heading') }}
          </h1>
          <t-loading />
        </div>
      </div>
      <div v-else-if="status === 'renewed'" style="display: flex">
        <t-icon icon="check" boxed type="success" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>{{ $t('pages.applicant_looking_renewal.renewed.heading') }}</h1>
          <p>{{ $t('pages.applicant_looking_renewal.renewed.subheading') }}</p>
        </div>
      </div>
      <div v-else style="display: flex">
        <t-icon icon="exclamation" boxed type="danger" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>{{ $t('pages.applicant_looking_renewal.failed.heading') }}</h1>
          <p>{{ $t('pages.applicant_looking_renewal.failed.subheading') }}</p>
        </div>
      </div>
    </div>
    <dashboard-image slot="secondary" name="terms-of-service" />
  </t-split-screen>
</template>

<script>
import Vue from 'vue';
import TLoading from '~/components/TLoading';
import DashboardImage from '~/components/DashboardImage';

export default Vue.extend({
  components: {
    TLoading,
    DashboardImage,
  },

  layout: 'standalone',

  data: () => ({
    status: 'loading',
  }),

  computed: {
    applicantId() {
      return this.$route.params.id;
    },

    token() {
      return this.$route.query.token;
    },
  },

  beforeMount() {
    if (!this.applicantId || !this.token) {
      this.status = 'error';
      return;
    }

    this.renewLooking();
  },

  methods: {
    async renewLooking() {
      try {
        const response = await this.$axios.post(`/v1/applicants/${this.applicantId}/renew-looking`, {
          token: this.token,
        });

        console.log(response);

        this.status = 'renewed';
      } catch (error) {
        this.status = 'error';
      }
    },
  },
});
</script>
